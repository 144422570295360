<template>
  <div>
    <div class="mb-5 d-flex justify-content-between">
      <h6>
        Co-Authors
        <small class="d-block my-2"
          ><span class="text-muted"
            >Note<span class="text-danger">*: </span></span
          >do not add yourself as an author, you will be added as the main
          author automatically</small
        >
      </h6>
      <!-- add author button -->
      <a
        class="btn btn-primary ms-3"
        data-bs-toggle="modal"
        data-bs-target="#authorModal"
      >
        Add a new author
        <!-- author icon -->
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
            fill="#ffff"
          ></path>
          <path
            opacity="0.3"
            d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
            fill="#ffff"
          ></path>
        </svg>
      </a>
    </div>

    <!-- Form for searching the authors -->
    <div class="mb-3">
      <label for="authors" class="form-label visually-hidden"
        >Choose the authors</label
      >
      <div class="liveSearchDropDown">
        <input
          type="text"
          class="form-control"
          name="authors"
          id="liveSearchInput"
          placeholder="Search for authors to mention them"
          v-model="currentSearch"
          autocomplete="off"
        />
        <div
          class="liveSearchResult"
          v-if="authors.length > 0 && !in_submission"
        >
          <template v-for="author in authors" :key="author.id">
            <span
              class="m-2 p-2 cursor-p"
              @click="pushAuthor(researchAuthors, author.email)"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                  fill="#2670b6"
                ></path>
                <path
                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                  fill="#2670b6"
                ></path>
              </svg>
              <a class="mx-2 fw-bold">{{ author.name }}</a>
              <small class="text-muted d-block ms-5">{{
                author.email
              }}</small></span
            >
          </template>
        </div>
        <div class="d-flex justify-content-center m-5" v-if="in_submission">
          <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Form -->

    <!-- authors area -->
    <div class="my-3" v-if="researchAuthors.length > 0">
      <span
        v-for="email in researchAuthors"
        :key="email"
        class="badge bg-primary rounded-pill m-1"
        >{{ email }}
        <!-- remove keyword button -->
        <a class="btn btn-sm p-0" @click="popAuthor(researchAuthors, email)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
              fill="#ffff"
            ></path>
            <path
              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
              fill="#ffff"
            ></path>
          </svg>
        </a>
      </span>
    </div>

    <!-- adding author modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="authorModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authorModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="authorModalLabel">Add a new author</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <!-- adding author form -->
          <vee-form
            @submit="addAuthor"
            :validationSchema="addNewAuthor"
            id="authorFrom"
          >
            <div class="modal-body">
              <!-- author name -->
              <div class="mb-3">
                <label class="form-label" for="authorName"
                  >Author name<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  type="text"
                  name="authorName"
                  class="form-control"
                  placeholder="the full author name goes here"
                />
                <error-message name="authorName" class="text-danger" />
              </div>

              <!-- author affiliation -->
              <div class="mb-3">
                <label class="form-label" for="authorAffiliation"
                  >Author affiliation<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  type="text"
                  name="authorAffiliation"
                  class="form-control form-control-title"
                  placeholder="E.g. Libyan international medical university"
                />
                <error-message name="authorAffiliation" class="text-danger" />
              </div>

              <!-- author ORCID -->
              <div class="mb-3">
                <label class="form-label" for="authorORCID">Author ORCID</label>
                <vee-field
                  type="text"
                  name="authorORCID"
                  class="form-control"
                  placeholder="the author ORCID code"
                />
                <error-message name="authorORCID" class="text-danger" />
              </div>

              <!-- author email -->
              <div class="mb-3">
                <label class="form-label" for="authorEmail"
                  >Author email<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  type="email"
                  name="authorEmail"
                  class="form-control"
                  placeholder="email@example.com"
                />
                <error-message name="authorEmail" class="text-danger" />
              </div>
            </div>
            <!-- author form ends here -->
            <div class="modal-footer">
              <div v-if="!in_submission">
                <button
                  type="button"
                  class="btn btn-white mx-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save author
                </button>
                <button type="reset" class="d-none" id="resetForm"></button>
              </div>
              <!-- spinners -->
              <div
                class="d-flex justify-content-center my-2"
                v-if="in_submission"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <!-- alert message -->
              <div
                v-if="message != null && !in_submission"
                class="alert d-flex align-items-center text-white mt-3 text-start"
                :class="messageClass"
                role="alert"
              >
                <div>{{ message }}</div>
              </div>
            </div>
          </vee-form>
        </div>
      </div>
    </div>
    <!-- End Modal -->
    <!-- adding author modal ends here -->
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "authors",
  props: {
    researchAuthors: {
      required: true,
      type: Array,
    },
    pushAuthor: {
      required: true,
      type: Function,
    },
    popAuthor: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      addNewAuthor: {
        authorName: "required|alpha_spaces|max:100",
        authorAffiliation: "required|alpha_spaces|max:100",
        authorORCID: "regex:^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$",
        authorEmail: "required|email",
      },
      authorForm: null,
      in_submission: false,
      message: null,
      messageClass: null,
      currentSearch: null,
      authors: [],
    };
  },
  methods: {
    async addAuthor(val) {
      this.in_submission = true;
      await axiosConfig
        .post("api/newAuthor", val)
        .then((res) => {
          console.log(res);
          this.messageClass = "alert-success";
          this.message = res.data;

          this.authorForm.reset();
        })
        .catch((err) => {
          console.log(err.response);
          this.messageClass = "alert-danger";
          err.response != null
            ? (this.message = Object.values(err.response.data.errors)[0][0])
            : (this.message =
                "An error has been occured, please try again later");
          return;
        });
      this.in_submission = false;
      setTimeout(() => {
        this.message = null;
      }, 3000);
    },
    async searchAuthors(currentSearch) {
      this.in_submission = true;
      let search = {
        search: currentSearch,
      };
      await axiosConfig
        .post("api/SearchAuthor", search)
        .then((res) => {
          console.log(res);
          this.authors = res.data;
        })
        .catch((err) => {
          console.log(err.resposne);
          return;
        });
    },
  },
  mounted() {
    this.authorForm = document.getElementById("authorFrom");
  },
  watch: {
    currentSearch: async function (newVal) {
      if (newVal) {
        this.authors = [];
        await this.searchAuthors(newVal);
        this.in_submission = false;
      }
    },
  },
};
</script>
