<template>
  <div class="mb-5 d-flex flex-row justify-content-between">
    <h6>File Attachment</h6>
    <!-- Button trigger modal -->
    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#HelpAttchedFiles"
    >
      Instructions
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 17C16.4183 17 20 13.4183 20 9C20 4.58172 16.4183 1 12 1C7.58172 1 4 4.58172 4 9C4 13.4183 7.58172 17 12 17Z"
          fill="#ffff"
        ></path>
        <path
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.53819 9L10.568 19.3624L11.976 18.8149L13.3745 19.3674L17.4703 9H6.53819ZM9.46188 11H14.5298L11.9759 17.4645L9.46188 11Z"
          fill="#ffff"
        ></path>
        <path
          opacity="0.3"
          d="M10 22H14V22C14 23.1046 13.1046 24 12 24V24C10.8954 24 10 23.1046 10 22V22Z"
          fill="#ffff"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18C15.5523 18 16 18.4477 16 19C16 19.5523 15.5523 20 15 20C15.5523 20 16 20.4477 16 21C16 21.5523 15.5523 22 15 22H9C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C8.44772 20 8 19.5523 8 19C8 18.4477 8.44772 18 9 18C8.44772 18 8 17.5523 8 17Z"
          fill="#ffff"
        ></path>
      </svg>
    </button>
    <!-- End Button trigger modal -->
  </div>

  <!-- Form for uplaoding your proposal-->
  <div class="row mb-md-5">
    <label
      for="proposalInput"
      class="col-sm-12 col-md-3 col-lg-3 col-form-label"
      >Upload your proposal template <i class="text-danger fw-bold">*</i></label
    >
    <div class="col-sm-9">
      <input
        type="file"
        class="form-control"
        id="proposalInput"
        name="proposalInput"
        placeholder=""
        @change="attach($event, attachedProposal)"
      />
      <a
        @click="downloadFile('templates/proposal_template.docx')"
        class="text-decoration-underline cursor-p"
        >Dowload the proposal template by clicking here.</a
      >
    </div>
  </div>
  <!-- End Form -->

  <!-- uplaod other files -->
  <div class="row">
    <div class="col-md-3 col-sm-12 col-form-label">
      <label for="" class="d-none d-md-block d-lg-block"
        >Upload any other files</label
      >
    </div>
    <div class="col-md-8 col-sm-12 col-8">
      <div class="area d-none d-md-block d-lg-block">
        <div
          :class="{ 'dragOver-dropZone': isDragOver }"
          id="dropZone"
          @drag.prevent.stop=""
          @dragstart.prevent.stop=""
          @dragend.prevent.stop="isDragOver = false"
          @dragover.prevent.stop="isDragOver = true"
          @dragenter.prevent.stop="isDragOver = true"
          @dragleave.prevent.stop="isDragOver = false"
          @drop.prevent.stop="attach($event, attachedFiles)"
        >
          Drop files here
        </div>
      </div>
    </div>
  </div>
  <!-- uplaod other files ends here -->
  <!-- Form for uplaoding attach manualy only on sm and xs screen-->
  <div class="row my-3 d-block d-xl-none d-lg-none d-md-none">
    <label for="emailCareersForm" class="col-sm col-form-label"
      >Choose other files to attach</label
    >
    <div class="col-sm-9">
      <input
        type="file"
        class="form-control"
        id="emailCareersForm"
        name="emailCareersFormName"
        placeholder=""
        aria-label="example@site.com"
        multiple
        @change="attach($event, attachedFiles)"
      />
    </div>
  </div>
  <!-- End Form -->

  <!-- attached files list -->
  <div v-if="attachedFiles.length > 0 || attachedProposal.length > 0">
    <b>attached files:</b>
    <ul class="text-primary">
      <!-- other files -->
      <li v-for="file in attachedFiles" :key="file.name">
        {{ file.name }}
        <!-- remove file button -->
        <a class="cursor-p mx-2" @click="popFromArray(attachedFiles, file)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#692340"
            class="bi bi-trash-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
            />
          </svg>
        </a>
      </li>
      <!-- the proposal tempplate -->
      <li v-for="file in attachedProposal" :key="file.name">
        {{ file.name }}
        <!-- remove file button -->
        <a class="cursor-p mx-2" @click="popFromArray(attachedProposal, file)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#692340"
            class="bi bi-trash-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
            />
          </svg>
        </a>
      </li>
    </ul>
  </div>
  <!-- end attached files list -->

  <!-- message -->
  <b v-if="message != null" class="text-danger fw-bold">{{ message }}</b>
  <!-- end message -->

  <!-- Modal for help and explain the necessary files to be attached -->
  <div
    id="HelpAttchedFiles"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="HelpAttchedFilesTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="HelpAttchedFilesTitle">
            Please read carefully
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul>
            <li>
              The form only allows 3 files to be submitted, one of them is
              mandatory, the proposal template which you can
              <a
                @click="downloadFile('templates/proposal_template.docx')"
                href="#"
                class="text-decoration-underline"
                >download it from here</a
              >
            </li>
            <li>
              Please fill out the proposal template once it is downloaded then
              re-upload it again in his corresponding input.
            </li>
            <li>The maximum size of a single file is 20 MB.</li>
            <li>
              The permitted extensions is PDF files and word document extensions
              only.
            </li>
            <li>
              Also you can download the Research priorities
              <a
                @click="
                  downloadFile('resources/Scientific_research_priorities.pdf')
                "
                href="#"
                class="text-decoration-underline"
                >by clicking here</a
              >
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Understood
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import { downloadFile } from "@/includes/helpers.js";

export default {
  name: "uploadFiles",
  props: {
    attachedFiles: {
      type: Array,
      required: true,
    },
    attachedProposal: {
      type: Array,
      required: true,
    },
    pushInArray: {
      type: Function,
      required: true,
    },
    popFromArray: {
      type: Function,
      required: true,
    },
    resetArray: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isDragOver: false,
      acceptedType: [],
      message: null,
    };
  },
  methods: {
    async attach($event, arrayFile) {
      this.resetArray(arrayFile);
      this.isDragOver = false;
      const files = $event.dataTransfer
        ? [...$event.dataTransfer.files]
        : [...$event.target.files];
      files.forEach(async (file) => {
        if (
          this.acceptedType.includes(file.type) &&
          file.size / 1000 / 1000 <= 20
        ) {
          this.pushInArray(arrayFile, file);
        } else {
          this.message =
            "please make sure that the files has PDF or word document extensions and the maximum size is 20 MB";
          setTimeout(() => {
            this.message = null;
          }, 6000);
        }
      });
      $event.currentTarget.value = "";
    },
    downloadFile,
  },
  created() {
    this.acceptedType = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      "application/msword",
    ];
  },
};
</script>

<style></style>
